import { createStore } from "vuex";
import axios from "axios";

export default createStore({
    state: {
        counter: 5
    },
    getters: {
        getCounter(state) {
            return state.counter > 0 ? state.counter : "counter数据异常";
        }
    },
    mutations: {
        addCounter(state, num) {
            state.counter += num;
        }
    },
    actions: {
        asyncAddCounter({ commit }) {
            // 替换为有效的 URL
            const url = "https://unsplash.com/developers";
            setTimeout(() => {
                commit('addCounter', 1);
              }, 1000);
        }    
    }
})