<template>
  <div class="controller">
    <button @click="moveCar(800)">移动到800PX</button>
    <button @click="moveCar(1200)">移动到1200PX</button>
    <button @click="moveCar(0)">回家</button>
  </div>
  <div class="mb-4">
    <el-button>Default</el-button>
    <el-button type="primary">Primary</el-button>
    <el-button type="success">Success</el-button>
    <el-button type="info">Info</el-button>
    <el-button type="warning">Warning</el-button>
    <el-button type="danger">Danger</el-button>
    <el-switch v-model="value1" />
  <el-switch
    v-model="value2"
    class="ml-2"
    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
  />
  </div>
  <div>
    <ul>
      <li v-for="(item,index) in names.list":key="index">{{ item }}</li>
    </ul>
  </div>
    
  <div class="road">
    <div class="road800"></div>
    <div class="road1200"></div>
    <div
      id="car"
      :style="{ left: carPosition + 'px' }"
      class="car"
    ></div>
  </div>

  <div id="div1"></div>
  <div id="app"></div>
  <div>
    <p>counter = {{ $store.state.counter }}</p>
  </div>
    
  <div class="hello">
    <Swiper class="myswiper" :modules="modules" :pagination="{ clickable: true }">
      <SwiperSlide>
        <img src="../assets/2.jpg" alt="">
      </SwiperSlide>
      <SwiperSlide>
        <img src="../assets/2.jpg" alt="">
      </SwiperSlide>
      <SwiperSlide>
        <img src="../assets/2.jpg" alt="">
      </SwiperSlide>
    </Swiper>
  </div>
  <img src="../assets/1.webp" alt="加载错误！" width="200px" title="小姐姐">
  <img src="../assets/2.webp" alt="加载错误！" width="220px" title="小姐姐">
  <h1>吃饭口号</h1>
  <p>没什么比吃饭更重要！<br>玩黑悟空吧！</p>
  <a href="http://www.zhongluhua.com">zhongluhua</a> 
</template>

<script>
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import api from "../api/index"
import axios from 'axios';
import { ref,reactive,onMounted,inject} from "vue";

export default {
  setup() {
    // 使用 ref 创建响应式数据
    const carPosition = ref(0); // 初始位置
    const carInterval = ref(null); // 定时器
    const value1 = ref(true);
    const value2 = ref(true);
    const names = reactive({
      list:["华华","pengpeng","weiming","jinbao","weiping","weigui"]
    });

    // 示例：定义一个方法来更新 carPosition
    function moveCar(target) {
      clearInterval(carInterval.value);
      carInterval.value = setInterval(() => {
        const step = 10;
        let current = carPosition.value;
        let nextPosition = current + (target > current ? step : -step);
        if (Math.abs(target - nextPosition) > Math.abs(step)) {
          carPosition.value = nextPosition;
        } else {
          carPosition.value = target;
          clearInterval(carInterval.value);
        }
      }, 20);
    };
     // 使用 onMounted 生命周期钩子
     onMounted(() => {
      console.log("生命周期函数：onMounted");
    });

    const message = inject("message")

    // 返回响应式数据和方法，使其在模板中可用
    return {
      carPosition,
      carInterval,
      names,
      message,
      value1,
      value2,
      moveCar
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  }

} 
  

</script>

<style scoped>
/* 控制器样式 */
.controller {
  width: 600px;
  height: 50px;
  line-height: 50px;
}

.controller button {
  outline: none;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 200px;
  height: 50px;
  font-size: 16px;
  line-height: 50px;
  text-align: center;
  background-color: #E9E9E9;
  cursor: pointer;
  float: left;
  box-sizing: border-box;
  border: 2px solid #F0F0F0;
}

.controller button:hover {
  background-color: #F9F9F9;
}

/* 公路样式 */
.road {
  width: 100%;
  height: 100px;
  position: relative;
  margin-top: 50px;
  background: #3DB1FF;
  opacity: .90;
}

.road800 {
  width: 800px;
  height: 100px;
  background: pink;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  opacity: .75;
}

.road1200 {
  width: 1200px;
  height: 100px;
  background: orange;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 500;
}

/* 小汽车样式 */
#car {
  width: 135px;
  height: 100px;
  display: block;
  background: black;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1500;
  box-sizing: border-box;
}

/* 动画样式 */
#div1 {
  width: 200px;
  height: 170px;
  background-color: red;
  animation: xhui 5s linear 0s infinite;
}

#div1:hover {
  animation-play-state: paused;
}

@keyframes xhui {
  0% {
    width: 200px;
    background-color: yellow;
  }
  25% {
    width: 500px;
    background-color: blueviolet;
  }
  50% {
    width: 900px;
    background-color: greenyellow;
  }
  75% {
    width: 500px;
    background-color: red;
  }
  100% {
    width: 200px;
    background-color: yellow;
  }
}
</style>





<style scoped>
/* 将你的 CSS 代码放在这里 */
.controller {
  width: 600px;
  height: 50px;
  line-height: 50px;
}
img {
  width: 30%;
}
</style>

