import { createMemoryHistory, createRouter } from 'vue-router'
import HomeVue from '@/vues/HomeVue.vue'
import About from '@/vues/About.vue'
import path from '@/api/path'
import NewsView from '@/vues/NewsView.vue';
import NewsDetailsView from '@/vues/NewsDetailsView.vue';
import AboutUs from '@/vues/AboutUs.vue';
import AboutInfo from '@/vues/AboutInfo.vue';

//配置信息中需要页面的相关配置
// 
const routes = [
    { path: '/about', component: About },
    { path: '/', component: HomeVue },
    { path: '/news', component: NewsView },
    {
      path: '/newsdetails/:name', 
      component: NewsDetailsView,
      children: [
        { path: 'us', component: AboutUs },
        { path: 'info', component: AboutInfo }
        // 可以继续添加更多的子路由
      ]
    },
    // 其他路由...
  ];


  const router = createRouter({
    history: createMemoryHistory(), // 如果你的应用是单页面应用，请使用 createWebHistory
    routes
  });
  
  export default router;