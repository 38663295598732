import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import axios from 'axios';
import router from './router';
import store  from "./store";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

// 创建 Vue 应用实例
const app = createApp(App);

app.use(ElementPlus);

// 使用路由
app.use(router);
// 使用 Vuex store
app.use(store);


// 将 axios 挂载到全局
app.config.globalProperties.$axios = axios;

// 将 Vue 应用挂载到 DOM 上
app.mount('#app');