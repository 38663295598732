<template>
  <RouterLink to="/">首页</RouterLink>|
  <RouterLink to="/about">关于</RouterLink>|
  <RouterLink to="/news">新闻</RouterLink>|
  
  <RouterView></RouterView>
  
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import {provide } from "vue"
export default {
  name: 'App',
  components: {
    HelloWorld
  },
  setup(){
    provide("message","我是黑悟空！")

  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
