import axios from "../utils/http";
import path from "./path"; // 确保路径正确  
  
const api = {  
    getWeather() { // 更清晰的函数名  
        // 使用 path 中定义的 URL 和 baseUrl  
        return axios.get(path.baseUrl + path.weatherApiPath)  
            .catch(error => {  
                console.error('API请求失败:', error); // 添加错误处理  
                throw error; // 可以选择重新抛出错误  
            });  
    }  
};  
  
export default api;
