import axios from "axios";  
import querystring from "querystring";  
  
// 错误处理函数  
const errorHandle = (status, info) => {  
    switch (status) {  
        case 400:  
            console.log("语义有误");  
            break;  
        case 401:  
            console.log("服务器认证失败");  
            break;  
        case 403:  
            console.log("服务器拒绝访问");  
            break;  
        case 404:  
            console.log("地址错误");  
            break;  
        case 500:  
            console.log("服务器遇到意外");  
            break;  
        case 502:  
            console.log("服务器无响应");  
            break;  
        default:  
            console.log(info);  
            break;  
    }  
};  
  
// 创建axios实例  
const instance = axios.create({  
    // 网络请求的公共配置  
    timeout: 5000  
});  
  
// 请求拦截器  
instance.interceptors.request.use(  
    config => {  
        if (config.method === "post") {  
            // 将post请求的data转换为查询字符串（这通常用于application/x-www-form-urlencoded）  
            // 注意：这取决于服务器期望的Content-Type。如果服务器期望JSON，则不应这样做。  
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded';  
            config.data = querystring.stringify(config.data);  
        }  
        return config;  
    },  
    error => {  
        // 请求错误处理  
        console.error('请求错误:', error);  
        return Promise.reject(error);  
    }  
);  
  
// 响应拦截器  
instance.interceptors.response.use(  
    response => {  
        // 这里通常只处理成功的响应（状态码2xx）  
        // 但也可以根据需要检查其他状态码  
        if (response.status === 200) {  
            return response.data; // 通常我们只关心响应的数据部分  
        } else {  
            // 非200状态码，可以调用错误处理函数  
            errorHandle(response.status, response.data || '未知错误');  
            return Promise.reject(new Error(`HTTP Error ${response.status}`));  
        }  
    },  
    error => {  
        // 响应错误处理  
        const { response } = error;  
        if (response) {  
            // 服务器已响应，但状态码表示错误  
            errorHandle(response.status, response.data || '未知错误');  
        } else {  
            // 处理请求错误，例如网络问题  
            console.error('请求失败:', error.message);  
        }  
        return Promise.reject(error);  
    }  
);  
  
export default instance;