<template>
    <div>
      <h3>首页</h3>
      <p> count = {{ $store.getters.getCounter }}</p>
      <p>{{ getCounter }}</p>
      <button @click="addClickHandle">增加</button>
      <button @click="addAsyncClickHandle">异步增加</button>
    </div>
  </template>
  
  <script>
  import { mapGetters ,mapMutations,mapActions} from 'vuex';
  export default {
    name: 'HomeVue',

    components: {    
    },
    computed: {
        ...mapGetters( ["getCounter"])
    },
    methods: {
        ...mapMutations(['addCounter']),
        ...mapActions(["asyncAddCounter"]),
        addClickHandle() {
        this.addCounter(20);
        },
        addAsyncClickHandle() {
            this.asyncAddCounter();
        }
        
    }
  };

  </script>